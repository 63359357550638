<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['returnGoods_ReturnedGoods',])"></div>
      <CardLeftBlock
          :name="$t('returnGoods_ReturnedGoods.localization_value.value')"
          :value="'#' + RG.data.Order.getId()"
          :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <div class="card-detail-left__date">
            {{ RG.data.Order.data.createdAt | moment("DD MMM, YYYY") }}
          </div>
        </template>

      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">

            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ex_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.problemsUnidentifiedReturns" class="order-create__head-back">
                  <LinkBack
                      :value="$t('ex_backTo.localization_value.value')"
                      @click.native="goRouterBack()"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['payPalCases_createCase',])"></div>
              {{ $t('returnGoods_ReturnedGoods.localization_value.value') }}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <Confirmation
                :RG="RG"
            />

          </div>

        </template>
        <template slot="footer">

        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
import Confirmation from "@/components/modules/ReturnGoodsModule/components/chunks/Confirmation/Confirmation";


export default {
  name: "ReturnGoodsShowSection",

  components: {
    Confirmation,
    CardLeftBlock,
    CardRightBlock,
    LinkBack,
  },

  props: {
    RG: Object,
    createData: Object,
  },

  data() {
    return {}
  },

  methods: {}
}
</script>

<style lang="scss">

.hide-upc {
  opacity: 0.5;
  pointer-events: none;
}

.product-progress-steps {
  max-width: 625px;
}

</style>
