<template>
  <ReturnGoodsShowSection
          :RG="RG"
          :createData="createData"
  />
</template>

<script>
  import {ReturnGoods} from "../models/ReturnGoods";
  import ReturnGoodsShowSection from "./ReturnGoodsShowSection/ReturnGoodsShowSection";
  import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";

  export default {
    name: "ReturnGoodsShow",

    components:{
      ReturnGoodsShowSection,
    },

    mixins: [returnGoodsMixin],

    data() {
      return {
        RG: new ReturnGoods(),
        transactionUser: {},
        createData: null,
      }
    },

    mounted() {
      this.initReturnGoodsEdit()
    },

    methods: {


      initReturnGoodsEdit() {

        this.RG.data.Order.setId(this.$route.params.id)

        this.$store.dispatch('getReturnGoods', this.RG.data.Order.getId()).then((response) => {
          let item = this.getRespData(response)
          this.RG.setItem(item, this)

          let files = item?.file_entities

          this.downloadFileFromArray(files).then((documents) => {
            let filesObj = this.RG.data.Files
            filesObj.setDownloadFiles(documents)
          }).catch(error => this.createErrorLog(error))

        }).catch(error => this.createErrorLog(error))
      },

    }

  }
</script>

<style scoped>

</style>
