<template>
	<div class="order-create__section">

		<div class="order-create__row custom-row">
			<div class="order-create__col custom-col">
				<div class="order-create__table block-table">
					<div class="block-table__row">
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'returnGoods_shipped',
						])"></div>
								{{$t('returnGoods_shipped.localization_value.value')}}
							</div>
							<div class="block-table__content">
                {{ RG.data.shippedDate | moment("DD MMM, YYYY") }}
							</div>
						</div>
						<div class="block-table__col block-table__col--20"
                 v-if="isAdmin"
            >
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'common_user',
						])"></div>
								{{$t('common_user.localization_value.value')}}
							</div>
							<div class="block-table__content">
                <template v-if="RG.data.User.getUserId()">
								  {{RG.data.User.getUserName()}}
                </template>
                <template v-else>—</template>
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'returnGoods_trackingNumber',
						])"></div>
								{{$t('returnGoods_trackingNumber.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{RG.data.trackingNumber}}
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'returnGoods_orderTrackingNumber',
						])"></div>
								{{$t('returnGoods_orderTrackingNumber.localization_value.value')}}
							</div>
							<div class="block-table__content">
                <template v-if="_.has(RG.data.trackingNumberObject, 'custom_tracking_number')">
                  {{RG.data.trackingNumberObject.custom_tracking_number}}
                </template>
                <template v-if="RG.data.orderTrackingNumber">
                  {{RG.data.orderTrackingNumber}}
                </template>
							</div>
						</div>

            <div class="block-table__col"
                 v-bind:class="{'block-table__col--40': isAdmin, 'block-table__col--60': !isAdmin}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
							'returnGoods_comment',
						])"></div>
                {{$t('returnGoods_comment.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{RG.data.comment}}
              </div>
            </div>
					</div>


          <div class="block-table__row">
            <div class="block-table__col">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
							'returnGoods_comment',
						])"></div>
<!--                {{$t('returnGoods_comment.localization_value.value')}}-->
              </div>
              <div class="block-table__content">

                <div class="custom-row">
                  <div class="custom-col custom-col--md-50 custom-col--sm-100 ml-3"
                       v-for="(item, index) in RG.data.Files.data.downloadFiles"
                       :key="index">
                    <div class="site-document d-flex justify-content-center">
                      <div class="site-document__img">
                        <img v-if="item.mime_type !== 'application/pdf'"
                             :src="`data:${item.type};base64,` + item.base64"
                             @click="showSingle(item.id + 1)"
                             alt="img"
                        >
                        <img v-else
                             @click="openPdf(item['base64'])"
                             style="width: 30px; height: 40px;"
                             src="/img/common/pdf_file_icon.svg"
                             alt="ico"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

				</div>
			</div>
		</div>

    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></Lightbox>
	</div>
</template>

<script>

	import Lightbox from "vue-easy-lightbox";
  import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";

  export default {
		name: "Confirmation",

		components: {
      Lightbox,
		},

    mixins: [returnGoodsMixin],

		props: {
      RG: Object,
		},

		data() {
			return {
        images: -1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, // default: 0
			}
		},

		methods: {

		}
	}
</script>

<style scoped>

.site-document__img{
  max-width: 100px;
  min-width: 100px;
  height: 100px;
}
</style>
