var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-create__section"},[_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col"},[_c('div',{staticClass:"order-create__table block-table"},[_c('div',{staticClass:"block-table__row"},[_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'returnGoods_shipped',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('returnGoods_shipped.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.RG.data.shippedDate,"DD MMM, YYYY"))+" ")])]),(_vm.isAdmin)?_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'common_user',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('common_user.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[(_vm.RG.data.User.getUserId())?[_vm._v(" "+_vm._s(_vm.RG.data.User.getUserName())+" ")]:[_vm._v("—")]],2)]):_vm._e(),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'returnGoods_trackingNumber',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('returnGoods_trackingNumber.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.RG.data.trackingNumber)+" ")])]),_c('div',{staticClass:"block-table__col block-table__col--20"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'returnGoods_orderTrackingNumber',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('returnGoods_orderTrackingNumber.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[(_vm._.has(_vm.RG.data.trackingNumberObject, 'custom_tracking_number'))?[_vm._v(" "+_vm._s(_vm.RG.data.trackingNumberObject.custom_tracking_number)+" ")]:_vm._e(),(_vm.RG.data.orderTrackingNumber)?[_vm._v(" "+_vm._s(_vm.RG.data.orderTrackingNumber)+" ")]:_vm._e()],2)]),_c('div',{staticClass:"block-table__col",class:{'block-table__col--40': _vm.isAdmin, 'block-table__col--60': !_vm.isAdmin}},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'returnGoods_comment',
						])}}}),_vm._v(" "+_vm._s(_vm.$t('returnGoods_comment.localization_value.value'))+" ")]),_c('div',{staticClass:"block-table__content"},[_vm._v(" "+_vm._s(_vm.RG.data.comment)+" ")])])]),_c('div',{staticClass:"block-table__row"},[_c('div',{staticClass:"block-table__col"},[_c('div',{staticClass:"block-table__label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
							'returnGoods_comment',
						])}}})]),_c('div',{staticClass:"block-table__content"},[_c('div',{staticClass:"custom-row"},_vm._l((_vm.RG.data.Files.data.downloadFiles),function(item,index){return _c('div',{key:index,staticClass:"custom-col custom-col--md-50 custom-col--sm-100 ml-3"},[_c('div',{staticClass:"site-document d-flex justify-content-center"},[_c('div',{staticClass:"site-document__img"},[(item.mime_type !== 'application/pdf')?_c('img',{attrs:{"src":`data:${item.type};base64,` + item.base64,"alt":"img"},on:{"click":function($event){return _vm.showSingle(item.id + 1)}}}):_c('img',{staticStyle:{"width":"30px","height":"40px"},attrs:{"src":"/img/common/pdf_file_icon.svg","alt":"ico"},on:{"click":function($event){return _vm.openPdf(item['base64'])}}})])])])}),0)])])])])])]),_c('Lightbox',{attrs:{"escDisabled":"","moveDisabled":"","visible":_vm.visible,"imgs":_vm.imgs,"index":_vm.index},on:{"hide":_vm.handleHide}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }